<template>
  <div>
    <!-- 评论 -->
    <div
      class="comment_details"
      v-for="(item, index) in comment"
      v-bind:index="index"
    >
      <div class="comment_name">{{ item.username }}</div>
      <div class="comment_content">{{ item.content }}</div>
      <!-- <el-button class="btn" type="mini" @click="shareBtn":style="{ display: visible }">分享到小组</el-button> -->
      <el-button v-if="item.authority==0" class="btn" type="mini" v-on:click.once="shareBtn">分享到小组</el-button>
      <!-- <el-button v-if="item.authority==1" class="btn" type="mini" disabled @click="">已分享</el-button> -->
      <el-button
        class="comment_reply"
        type="text"
        @click="listReplyBtn(item.id, item.content)"
        >共{{ item.total }}条回复></el-button
      >
      <div class="comment_time">{{ item.time }}</div>
      <el-button
        icon="el-icon-chat-round"
        title="回复"
        size="mini"
        type="text"
        @click="replyBtn(item.id, item.username, item.userId, item.content)"
      ></el-button>
      <el-button
        icon="el-icon-delete"
        title="删除"
        size="mini"
        type="text"
        @click="deleteCommentBtn(item.id, item.content)"
      ></el-button>
      <el-button
        icon="el-icon-arrow-up"
        title="收起回复"
        size="mini"
        type="text"
        @click="callBack(item.id, item.content)"
      ></el-button>
      <el-button
        icon="el-icon-star-on"
        title="赞同"
        size="mini"
        type="text"
        @click="agreeComment(item.id, item.content)"
        >{{ item.praise }}</el-button
      >
      <el-button
        icon="el-icon-star-off"
        title="反对"
        size="mini"
        type="text"
        @click="deleagreeComment(item.id, item.content)"
      ></el-button>
      <el-button
        icon="el-icon-question"
        title="疑问"
        size="mini"
        type="text"
        @click="askComment(item.id, item.content)"
        >{{ item.doubt }}</el-button
      >
      <el-button
        icon="el-icon-finished"
        title="已解决"
        size="mini"
        type="text"
        @click="deleaskComment(item.id, item.content)"
      ></el-button>
      <el-rate v-model="item.score" disabled score-template="{item.score}">
      </el-rate>
      <span class="comment_score">分数:{{ item.score }}</span>
      <div
        class="reply_details"
        v-for="(reply, index) in replyment"
        v-bind:index="index"
        v-if="item.id == reply.pingLunId"
      >
        <span class="comment_name">{{ reply.username }} </span>
        <span style="font-size: 13px">回复</span>
        <span class="comment_name">{{ reply.receiver }} </span>
        <div class="comment_content">{{ reply.content }}</div>
        <div class="reply_time">{{ reply.time }}</div>
        <el-button
          icon="el-icon-delete"
          title="删除"
          size="mini"
          type="text"
          @click="deleteReplyBtn(reply.id, item.content, reply.content)"
        ></el-button>
        <el-button
          icon="el-icon-star-on"
          title="赞同"
          size="mini"
          type="text"
          @click="agreeReply(reply.id, item.content, reply.content)"
          >{{ reply.praise }}</el-button
        >
        <el-button
          icon="el-icon-star-off"
          title="反对"
          size="mini"
          type="text"
          @click="deleagreeReply(reply.id, item.content, reply.content)"
        ></el-button>
        <el-button
          icon="el-icon-question"
          title="疑问"
          size="mini"
          type="text"
          @click="askReply(reply.id, item.content, reply.content)"
          >{{ reply.doubt }}</el-button
        >
        <el-button
          icon="el-icon-finished"
          title="已解决"
          size="mini"
          type="text"
          @click="deleaskReply(reply.id, item.content, reply.content)"
        ></el-button>
        <el-rate v-model="reply.score" disabled score-template="{reply.score}">
        </el-rate>
        <span class="comment_score">得分:{{ reply.score }}</span>
      </div>
    </div>
    <!-- 发表评论部分 -->
    <div id="addcomDiv" class="commentBox" style="display: none">
      <h3 style="width: 100px">发表评论</h3>
      <b v-if="isReply">你回复&nbsp;{{ reName }}</b>
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        placeholder="请输入内容"
        v-model="commentText"
        maxlength="250"
      >
      </el-input>
      <div style="display: flex">
        <el-button class="btn" size="mini" @click="addComment">发表</el-button>
        <el-button class="btn" size="mini" @click="canelComment"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CommemtContent",
  props: {
    comment: Array,
    replyment: Array,
    articleId: "",
    hlId: "",
    disDiv: "",
  },
  data() {
    return {
      isReply: false,
      reName: "",
      reId: "",
      commentText: "",
      receiverId: "",
      tempcomment: [],
      tempreply: [],
    };
  },
  watch: {
    disDiv(val) {
      if (val == true) {
        document.getElementById("addcomDiv").style.display = "none";
      } else if (val == false) {
        document.getElementById("addcomDiv").style.display = "inline";
      }
    },
  },
  mounted() {
    this.currentTime();
    // this.queryParam();
  },
  methods: {
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位,则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位,则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位,则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },
    //分享评论到小组
    shareBtn() {
      var groupId = localStorage.getItem("groupId");
      var highlightId = sessionStorage.getItem("highlightId");
      var userId = localStorage.getItem("userId");
      var authority = sessionStorage.getItem("authority");
      axios
        .post(axios.defaults.baseURL + "articleTask/copyHighlight", {
          groupId:groupId,
          highlightId:highlightId,
        })
        .then((res) => {
        });
      axios
        .post(axios.defaults.baseURL + "copyPingLun", {
          highLightId: highlightId,
          authority: authority,
          userId: userId,
        })
        .then((res) => {
        });
      },
    //点赞评论
    agreeComment(id, content) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "addZan", {
          type_id: id,
          type: 1,
          userId: userId,
        })
        .then((res) => {
          if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                userId: userId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                groupId: groupId,
                // userId: 0,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else {
            this.$message.warning("权限错误");
          }
        });
      this.saveLog("点赞评论<" + content + ">", nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "点赞评论<" + content + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    //点赞回复
    agreeReply(id,conC,conR) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "addZan", {
          type_id: id,
          type: 2,
          userId: userId,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
      this.saveLog("点赞评论<" + conC + ">的回复<" + conR + ">", this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "点赞评论<" + conC + ">的回复<" + conR + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});
      button.Enable = false;
    },
    //疑问评论
    askComment(id,content) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "addQuestion", {
          type_id: id,
          type: 1,
          userId: userId,
        })
        .then((res) => {
          if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                userId: userId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                groupId: groupId,
                userId: 0,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else {
            this.$message.warning("权限错误");
          }
        });
      this.saveLog("对评论<" + content + ">提出疑问", this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "对评论<" + content + ">提出疑问",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    //疑问回复
    askReply(id,conC,conR) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "addQuestion", {
          type_id: id,
          type: 2,
          userId: userId,
        })
        .then((res) => {
          //console.log(res.data);
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
      this.saveLog("对评论<" + conC + ">的回复<" + conR + ">提出疑问",this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "对评论<" + conC + ">的回复<" + conR + ">提出疑问",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    //取消评论点赞
    deleagreeComment(id,content) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "deleteZan", {
          type_id: id,
          type: 1,
          userId: userId,
        })
        .then((res) => {
          if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                userId: userId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                groupId: groupId,
                userId: 0,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else {
            this.$message.warning("权限错误");
          }
        });
        this.saveLog("取消点赞评论<" + content + ">", this.nowDate);
        var userId = localStorage.getItem("userId");
        var username = localStorage.getItem("username");
        var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username: username,
          groupId: groupId,
          stuId: userId,
          log: "取消点赞评论<" + content + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    // 取消回复点赞
    deleagreeReply(id,conC,conR) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "deleteZan", {
          type_id: id,
          type: 2,
          userId: userId,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
        this.saveLog(
        "取消点赞评论<" + conC + ">的回复<" + conR + ">",
        this.nowDate
      );
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "取消点赞评论<" + conC + ">的回复<" + conR + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    //取消评论疑问
    deleaskComment(id,content) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "deleteQuestion", {
          type_id: id,
          type: 1,
          userId: userId,
        })
        .then((res) => {
          if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                userId: userId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                groupId: groupId,
                userId: 0,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else {
            this.$message.warning("权限错误");
          }
        });
        this.saveLog("对评论<" + content + ">取消疑问", this.nowDate);
        var userId = localStorage.getItem("userId");
        var username = localStorage.getItem("username");
        var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "对评论<" + content + ">取消疑问",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    //取消回复疑问
    deleaskReply(id,conC,conR) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "deleteQuestion", {
          type_id: id,
          type: 2,
          userId: userId,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
        this.saveLog(
        "取消对评论<" + conC + ">的回复<" + conR + ">提出的疑问",
        this.nowDate
      );
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "取消对评论<" + conC + ">的回复<" + conR + ">提出的疑问",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    //评论打分
    scoreComment(id, score) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "addPScore", {
          id: id,
          score: score,
        })
        .then((res) => {
          if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                userId: userId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
                groupId: groupId,
                userId: 0,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: authority,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            return [];
          } else {
            this.$message.warning("权限错误");
          }
        });
    },
    //回复打分
    scoreReply(id, score) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "addHScore", {
          id: id,
          score: score,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
    },
    deleteReplyBtn(id,conC,conR) {
      var userId = localStorage.getItem("userId");
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "deleteHuiFuById", {
          userId: userId,
          id: id,
        })
        .then((ret) => {
          let code = ret.data.status;
          if (code == 1) {
            this.$message.success("删除成功");
            axios
              .post(axios.defaults.baseURL + "listAllHuifuByPId", {
                pingLunId: id,
              })
              .then((response) => {
                this.$emit("childhuifu", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            if (authority == 0) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: authority,
                  userId: userId,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                })
                .catch((err) => {
                  console.log(err);
                });
              return [];
            } else if (authority == 1) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: authority,
                  groupId: groupId,
                  userId: 0,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                })
                .catch((err) => {
                  console.log(err);
                });
              return [];
            } else if (authority == 2) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: authority,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                })
                .catch((err) => {
                  console.log(err);
                });
              return [];
            } else {
              this.$message.warning("权限错误");
            }
          } else if (code == 0) {
            this.$message.error("删除失败");
          }
        });
        this.saveLog("删除评论<" + conC + ">的回复<" + conR + ">", this.nowDate);
        var userId = localStorage.getItem("userId");
        var username = localStorage.getItem("username");
        var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "删除评论<" + conC + ">的回复<" + conR + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    callBack(reId,content) {
      axios
        .post(axios.defaults.baseURL + "listAllHuifuByPId", {
          pingLunId: reId,
        })
        .then((response) => {
          this.$emit("childhuifu", null);
        })
        .catch((err) => {
          console.log(err);
        });
          this.saveLog("收起评论<" + content + ">的回复", this.nowDate);
          var userId = localStorage.getItem("userId");
          var username = localStorage.getItem("username");
          var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "收起评论<" + content + ">的回复",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    deleteCommentBtn(id,content) {
      var userId = localStorage.getItem("userId");
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("authority");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "deletePingLunById", {
          userId: userId,
          id: id,
        })
        .then((ret) => {
          let code = ret.data.status;
          if (code == 1) {
            this.$message.success("删除成功");
            if (authority == 0) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: authority,
                  userId: userId,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                })
                .catch((err) => {
                  console.log(err);
                });
              return [];
            } else if (authority == 1) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: authority,
                  groupId: groupId,
                  userId: 0,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                })
                .catch((err) => {
                  console.log(err);
                });
              return [];
            } else if (authority == 2) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: authority,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                })
                .catch((err) => {
                  console.log(err);
                });
              return [];
            } else {
              this.$message.warning("权限错误");
            }
          } else if (code == 0) {
            this.$message.error("删除失败");
          }
        });
         this.saveLog("删除评论<" + content + ">", this.nowDate);
         var userId = localStorage.getItem("userId");
         var username = localStorage.getItem("username");
         var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "删除评论<" + content + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    replyBtn(reId, reName, userId,reContent) {
      //reId  所回复评论的id, reName 所回复评论的评论人
      this.isReply = true;
      this.reId = reId; //评论id
      this.reName = reName; //评论人
      this.receiverId = userId;
      this.saveLog("回复评论<" + reContent + ">", this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "回复评论<" + reContent + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    listReplyBtn(reId,reContent) {
      //console.log(reId);
      axios
        .post(axios.defaults.baseURL + "listAllHuifuByPId", {
          pingLunId: reId,
        })
        .then((response) => {
          this.$emit("childhuifu", response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
         this.saveLog("查看评论<" + reContent + ">的所有回复", this.nowDate);
         var userId = localStorage.getItem("userId");
         var username = localStorage.getItem("username");
         var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "查看评论<" + reContent + ">的所有回复",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    addComment() {
      if (this.commentText == "") {
        this.$message.warning("请先选则要批注的部分");
      } else {
        //将评论插入后台
        var userId = localStorage.getItem("userId");
        var username = localStorage.getItem("username");
        var value = sessionStorage.getItem("readingtaskId");
        var highlightId = sessionStorage.getItem("highlightId");
        var authority = sessionStorage.getItem("authority");
        var groupId = localStorage.getItem("groupId");
        if (this.isReply) {
          axios
            .post(axios.defaults.baseURL + "addHuiFu", {
              userId: userId,
              username: username,
              content: this.commentText,
              pingLunId: this.reId, //评论完
              receiverId: this.receiverId,
              highLightId: highlightId,
              receiver: this.reName,
            })
            .then((response) => {
              if (authority == 0) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: authority,
                    userId: userId,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                return [];
              } else if (authority == 1) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: authority,
                    groupId: groupId,
                    userId: 0,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                return [];
              } else if (authority == 2) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: authority,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                return [];
              } else {
                this.$message.warning("权限错误");
              }
              axios
                .post(axios.defaults.baseURL + "listAllHuifuByPId", {
                  pingLunId: this.reId,
                })
                .then((response) => {
                  this.$emit("childhuifu", response.data.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            });
        } else {
          axios
            .post(axios.defaults.baseURL + "addPingLun", {
              userId: userId,
              username: username,
              content: this.commentText,
              highLightId: highlightId,
              articleId: value,
              authority: authority,
              groupId: groupId,
            })
            .then((response) => {
              //动态显示到页面上
              if (authority == 0) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: authority,
                    userId: userId,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                return [];
              } else if (authority == 1) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: authority,
                    groupId: groupId,
                    userId: 0,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                return [];
              } else if (authority == 2) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: authority,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                return [];
              } else {
                this.$message.warning("权限错误");
              }
            });
        }
        this.commentText = "";
        this.isReply = false;
        this.saveLog("发表评论", this.nowDate);
         var userId = localStorage.getItem("userId");
         var username = localStorage.getItem("username");
         var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "发表评论",
          createTime: this.nowDate,
        })
        .then((res) => {});
      }
    },
    canelComment() {
      this.commentText = "";
      this.isReply = false;
      this.saveLog("取消评论", this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "取消评论",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.comment_details {
  float: left;
  width: 100%;
  margin: 15px 0 10px 0px;
}
//名字
.comment_name {
  color: gray;
  font-size: 13px;
  font-weight: border;
}
//内容
.comment_content {
  color: black;
  margin-top: 5px;
  font-size: 13px;
}
//共有几条回复
.comment_reply {
  margin-top: 0px;
  font-size: 8px;
}
//时间
.comment_time {
  margin-top: 0px;
  font-size: 6px;
}
.comment_score {
  color: red;
  margin-top: 5px;
  font-size: 13px;
}
//回复时间
.reply_time {
  color: dimgrey;
  margin-top: 5px;
  font-size: 6px;
}
//评论框
.commentBox {
  margin: 0px;
}

.comment {
  margin-bottom: 15px;
}
.reply_details {
  margin: 15px 0 15px 50px;
}
</style>
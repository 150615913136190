<template>
  <div>
    <!-- 评论 -->
    <div
      class="comment_details"
      v-for="(item, index) in comment"
      v-bind:index="index"
    >
      <div class="comment_name">{{ item.username }}</div>
      <div class="comment_content">{{ item.content }}</div>
      <el-button
        class="comment_reply"
        type="text"
        @click="listReplyBtn(item.id)"
        >共{{ item.total }}条回复></el-button
      >
      <div class="comment_time">{{ item.time }}</div>
      <el-button
        icon="el-icon-chat-round"
        title="回复"
        size="mini"
        type="text"
        @click="replyBtn(item.id, item.username, item.userId)"
      ></el-button>
      <el-button
        icon="el-icon-delete"
        title="删除"
        @click="deleteCommentBtn(item.id)"
        size="mini"
        type="text"
      ></el-button>
      <el-button
        size="mini"
        type="text"
        @click="agreeComment(item.id)"
        icon="el-icon-star-on"
        title="赞同"
        >{{ item.praise }}</el-button
      >
      <el-button
        size="mini"
        type="text"
        @click="deleagreeComment(item.id)"
        icon="el-icon-star-off"
        title="反对"
      ></el-button>
      <el-button
        size="mini"
        type="text"
        @click="askComment(item.id)"
        icon="el-icon-question"
        title="疑问"
        >{{ item.doubt }}</el-button
      >
      <el-button
        size="mini"
        type="text"
        @click="deleaskComment(item.id)"
        icon="el-icon-finished"
        title="已解决"
      ></el-button>
      <el-button
        size="mini"
        type="text"
        @click="callBack(item.id)"
        icon="el-icon-arrow-up"
        title="收起回复"
      ></el-button>
      <!-- <el-input style="width:100px; height:20px" v-model="tempcomment[index]" placeholder="请打分"></el-input> -->
      <el-rate
        v-model="tempcomment[index]"
        @change="scoreComment(item.id, tempcomment[index])"
      >
      </el-rate>
      <span class="comment_score">最近一次打分:{{ item.score }}</span>
      <!-- <el-button
        size="mini"
        type="text"
        @click="scoreComment(item.id, tempcomment[index])"
        icon="el-icon-edit"
        title="确认分数"
        >{{ item.score }}</el-button
      > -->
      <div
        class="reply_details"
        v-for="(reply, index) in replyment"
        v-bind:index="index"
        v-if="item.id == reply.pingLunId"
      >
        <span class="comment_name">{{ reply.username }} </span>
        <span style="font-size: 13px">回复</span>
        <span class="comment_name">{{ reply.receiver }} </span>
        <div class="reply_content">{{ reply.content }}</div>
        <div class="reply_time">{{ reply.time }}</div>
        <el-button
          size="mini"
          type="text"
          @click="deleteReplyBtn(reply.id)"
          icon="el-icon-delete"
          title="删除"
        ></el-button>
        <el-button
          size="mini"
          type="text"
          @click="agreeReply(reply.id)"
          icon="el-icon-star-on"
          title="赞同"
          >{{ reply.praise }}</el-button
        >
        <el-button
          size="mini"
          type="text"
          @click="deleagreeReply(reply.id)"
          icon="el-icon-star-off"
          title="反对"
        ></el-button>
        <el-button
          size="mini"
          type="text"
          @click="askReply(reply.id)"
          icon="el-icon-question"
          title="疑问"
          >{{ reply.doubt }}</el-button
        >
        <el-button
          size="mini"
          type="text"
          @click="deleaskReply(reply.id)"
          icon="el-icon-finished"
          title="已解决"
        ></el-button>
        <!-- <el-input style="width:100px" v-model="tempreply[index]" placeholder="请打分"></el-input> -->
        <el-rate
          v-model="tempreply[index]"
          @change="scoreReply(reply.id, tempreply[index])"
        >
        </el-rate>
        <span class="comment_score">最近一次打分:{{ reply.score }}</span>
        <!-- <el-button
          size="mini"
          type="text"
          @click="scoreReply(reply.id, tempreply[index])"
          icon="el-icon-edit"
          title="确认分数"
          >{{ reply.score }}</el-button
        > -->
      </div>
    </div>
    <!-- 发表评论部分 -->
    <div class="commentBox">
      <h3 style="width: 100px">发表评论</h3>
      <b v-if="isReply">你回复&nbsp;{{ reName }}</b>
      <!-- <textarea
        name=""
        value="请填写评论内容"
        v-model="commentText"
        rows="4"
        cols="48"
        style="resize: none"
      ></textarea> -->
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        placeholder="请输入内容"
        v-model="commentText"
      >
      </el-input>
      <div style="margin: 20px 0"></div>
      <div style="display: flex;margin-top:10px">
        <el-button class="btn" size="mini" @click="addComment">发表</el-button>
        <el-button class="btn" size="mini" @click="canelComment">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CommemtContent1",
  props: {
    comment: Array,
    replyment: Array,
    articleId: "",
    hlId: "",
    groupId: "",
    authority: "",
  },
  mounted() {},
  data() {
    return {
      isReply: false,
      reName: "",
      reId: "",
      commentText: "",
      receiverId: "",
      tempcomment: [],
      tempreply: [],
    };
  },

  methods: {
    //点赞评论
    agreeComment(id) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("teacherAuth");
      var groupId = sessionStorage.getItem("teacherGro");
      var userId = localStorage.getItem("userId");
      var stuId = sessionStorage.getItem("stuId");
      axios
        .post(axios.defaults.baseURL + "addZan", {
          type_id: id,
          type: 1,
          userId: userId,
        })
        .then((res) => {
          if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: 0,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 0,
                userId: stuId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 1 && stuId != 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: stuId,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 2,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          }
        });
    },
    //点赞回复
    agreeReply(id) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "addZan", {
          type_id: id,
          type: 2,
          userId: userId,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
    },
    //疑问评论
    askComment(id) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("teacherAuth");
      var groupId = sessionStorage.getItem("teacherGro");
      var userId = localStorage.getItem("userId");
      var stuId = sessionStorage.getItem("stuId");
      axios
        .post(axios.defaults.baseURL + "addQuestion", {
          type_id: id,
          type: 1,
          userId: userId,
        })
        .then((res) => {
          if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: 0,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 0,
                userId: stuId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 1 && stuId != 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: stuId,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 2,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          }
        });
    },
    //疑问回复
    askReply(id) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "addQuestion", {
          type_id: id,
          type: 2,
          userId: userId,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
    },
    //取消评论点赞
    deleagreeComment(id) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("teacherAuth");
      var groupId = sessionStorage.getItem("teacherGro");
      var userId = localStorage.getItem("userId");
      var stuId = sessionStorage.getItem("stuId");
      axios
        .post(axios.defaults.baseURL + "deleteZan", {
          type_id: id,
          type: 1,
          userId: userId,
        })
        .then((res) => {
          if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: 0,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 0,
                userId: stuId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 1 && stuId != 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: stuId,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 2,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          }
        });
    },
    // 取消回复点赞
    deleagreeReply(id) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "deleteZan", {
          type_id: id,
          type: 2,
          userId: userId,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
    },
    //取消评论疑问
    deleaskComment(id) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("teacherAuth");
      var groupId = sessionStorage.getItem("teacherGro");
      var userId = localStorage.getItem("userId");
      var stuId = sessionStorage.getItem("stuId");
      axios
        .post(axios.defaults.baseURL + "deleteQuestion", {
          type_id: id,
          type: 1,
          userId: userId,
        })
        .then((res) => {
          if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: 0,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 0,
                userId: stuId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 1 && stuId != 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: stuId,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 2,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          }
        });
    },
    //取消回复疑问
    deleaskReply(id) {
      var userId = localStorage.getItem("userId");
      axios
        .post(axios.defaults.baseURL + "deleteQuestion", {
          type_id: id,
          type: 2,
          userId: userId,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
    },
    //评论打分
    scoreComment(id, score) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("teacherAuth");
      var groupId = sessionStorage.getItem("teacherGro");
      var userId = localStorage.getItem("userId");
      var stuId = sessionStorage.getItem("stuId");
      axios
        .post(axios.defaults.baseURL + "addPScore", {
          id: id,
          score: score,
        })
        .then((res) => {
          if (authority == 1) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: 0,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 0,
                userId: stuId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 1 && stuId != 0) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 1,
                userId: stuId,
                groupId: groupId,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          } else if (authority == 2) {
            axios
              .post(axios.defaults.baseURL + "listPingLunByHId", {
                highLightId: highlightId,
                authority: 2,
              })
              .then((response) => {
                this.$emit("child", response.data.data);
              });
          }
        });
    },
    //回复打分
    scoreReply(id, score) {
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("teacherAuth");
      var groupId = sessionStorage.getItem("teacherGro");
      var userId = localStorage.getItem("userId");
      var stuId = sessionStorage.getItem("stuId");
      axios
        .post(axios.defaults.baseURL + "addHScore", {
          id: id,
          score: score,
        })
        .then((res) => {
          axios
            .post(axios.defaults.baseURL + "listAllHuifuByPId", {
              pingLunId: id,
            })
            .then((response) => {
              this.$emit("childhuifu", response.data.data);
            });
        });
    },
    deleteReplyBtn(id) {
      var userId = localStorage.getItem("userId");
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("teacherAuth");
      var groupId = sessionStorage.getItem("teacherGro");
      var stuId = sessionStorage.getItem("stuId");
      axios
        .post(axios.defaults.baseURL + "deleteHuiFuById", {
          userId: userId,
          id: id,
        })
        .then((ret) => {
          let code = ret.data.status;
          if (code == 1) {
            this.$message.success("删除成功");
            axios
              .post(axios.defaults.baseURL + "listAllHuifuByPId", {
                pingLunId: id,
              })
              .then((response) => {
                this.$emit("childhuifu", response.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
            if (authority == 1) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: 1,
                  userId: 0,
                  groupId: groupId,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                });
            } else if (authority == 0) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: 0,
                  userId: stuId,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                });
            } else if (authority == 1 && stuId != 0) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: 1,
                  userId: stuId,
                  groupId: groupId,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                });
            } else if (authority == 2) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: 2,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                });
            }
          } else if (code == 0) {
            this.$message.error("删除失败");
          }
        });
    },
    callBack(reId) {
      axios
        .post(axios.defaults.baseURL + "listAllHuifuByPId", {
          pingLunId: reId,
        })
        .then((response) => {
          this.$emit("childhuifu", null);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteCommentBtn(id) {
      var userId = localStorage.getItem("userId");
      var highlightId = sessionStorage.getItem("highlightId");
      var authority = sessionStorage.getItem("teacherAuth");
      var groupId = sessionStorage.getItem("teacherGro");
      var stuId = sessionStorage.getItem("stuId");
      axios
        .post(axios.defaults.baseURL + "deletePingLunById", {
          userId: userId,
          id: id,
        })
        .then((ret) => {
          let code = ret.data.status;
          if (code == 1) {
            if (authority == 1) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: 1,
                  userId: 0,
                  groupId: groupId,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                });
            } else if (authority == 0) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: 0,
                  userId: stuId,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                });
            } else if (authority == 1 && stuId != 0) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: 1,
                  userId: stuId,
                  groupId: groupId,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                });
            } else if (authority == 2) {
              axios
                .post(axios.defaults.baseURL + "listPingLunByHId", {
                  highLightId: highlightId,
                  authority: 2,
                })
                .then((response) => {
                  this.$emit("child", response.data.data);
                });
            }
          } else if (code == 0) {
            this.$message.error("删除失败");
          }
        });
    },
    replyBtn(reId, reName, userId) {
      //reId  所回复评论的id， reName 所回复评论的评论人
      this.isReply = true;
      this.reId = reId; //评论id
      this.reName = reName; //评论人
      this.receiverId = userId;
    },
    listReplyBtn(reId) {
      axios
        .post(axios.defaults.baseURL + "listAllHuifuByPId", {
          pingLunId: reId,
        })
        .then((response) => {
          this.$emit("childhuifu", response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addComment() {
      if (this.commentText == "") {
        this.$message.warning("请先选则要批注的部分");
      } else {
        //将评论插入后台
        var userId = localStorage.getItem("userId");
        var username = localStorage.getItem("username");
        var value = sessionStorage.getItem("readingtaskId");
        var highlightId = sessionStorage.getItem("highlightId");
        var authority = sessionStorage.getItem("teacherAuth");
        var groupId = sessionStorage.getItem("teacherGro");
        var stuId = sessionStorage.getItem("stuId");
        if (this.isReply) {
          axios
            .post(axios.defaults.baseURL + "addHuiFu", {
              userId: userId,
              username: username,
              content: this.commentText,
              pingLunId: this.reId, //评论完
              receiverId: this.receiverId,
              highLightId: highlightId,
              receiver: this.reName,
            })
            .then((response) => {
              if (authority == 1) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: 1,
                    userId: 0,
                    groupId: groupId,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  });
              } else if (authority == 0) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: 0,
                    userId: stuId,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  });
              } else if (authority == 1 && stuId != 0) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: 1,
                    userId: stuId,
                    groupId: groupId,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  });
              } else if (authority == 2) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: 2,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  });
              }
            });
        } else {
          axios
            .post(axios.defaults.baseURL + "addPingLun", {
              userId: userId,
              username: username,
              content: this.commentText,
              highLightId: highlightId,
              articleId: value,
              authority: authority,
              groupId: groupId,
            })
            .then((response) => {
              //动态显示到页面上
              if (authority == 1) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: 1,
                    userId: 0,
                    groupId: groupId,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  });
              } else if (authority == 0) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: 0,
                    userId: stuId,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  });
              } else if (authority == 1 && stuId != 0) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: 1,
                    userId: stuId,
                    groupId: groupId,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  });
              } else if (authority == 2) {
                axios
                  .post(axios.defaults.baseURL + "listPingLunByHId", {
                    highLightId: highlightId,
                    authority: 2,
                  })
                  .then((response) => {
                    this.$emit("child", response.data.data);
                  });
              }
            });
        }
        this.commentText = "";
        this.isReply = false;
      }
    },
    canelComment() {
      this.commentText = "";
      this.isReply = false;
    },
  },
};
</script>

<style lang="less" scoped>
.comment_details {
  float: left;
  width: 100%;
  margin: 15px 0 10px 0px;
}
//名字
.comment_name {
  color: gray;
  font-size: 13px;
  font-weight: border;
}
//内容
.comment_content {
  color: black;
  margin-top: 5px;
  font-size: 13px;
  width: 100%;
}
.comment_score{
  color:red;
  margin-top: 5px;
  font-size: 13px;
}
.reply_content {
  width: 100%;
  color: black;
  margin-top: 5px;
  font-size: 13px;
}
//共有几条回复
.comment_reply {
  margin-top: 0px;
  font-size: 8px;
}
//时间
.comment_time {
  margin-top: 0px;
  font-size: 6px;
}
//回复时间
.reply_time {
  color: dimgrey;
  margin-top: 5px;
  font-size: 6px;
}
//评论框
.commentBox {
  margin: 0px;
}

.comment {
  margin-bottom: 15px;
}
.reply_details {
  margin: 15px 0 15px 50px;
}
</style>
<template>
  <div>
    <!-- 高亮按钮 -->
    <el-button id="js-highlight" class="btnHighlight" @click="highlightText">高亮</el-button>

    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/allcourse' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>阅读任务</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 阅读区域 -->
    <div style="display: flex; width: 100%; height: 100%">
      <el-card style="width: 1200px; margin-right: 20px; height: 100%">
        <div slot="header">
          <div style="margin: 20px 0">
            <!--个人是0,组是1,公共是2 -->
            <!-- <el-button
              v-for="(list, index) in leftPart"
              @click="
                showArticleHL(1);
                leftChange(1);
              "
              :class="{ liBackground: changeLeftBackground == index }"
            >
              {{ list.name }}
            </el-button> -->
            
          </div>
        </div>
        <div style="height: 425px; width: 100%">
          <el-scrollbar id="scro" style="height: 100%">
            <div
              class="selfContainer"
              id="articleDiv"
              v-html="articleHtml"
            >
            </div>
          </el-scrollbar>
        </div>
      </el-card>

      <!-- 评论区域 -->
      <el-card style="width: 600px; height: 100%">
        <div style="height: 541.6px; width: 100%">
          <el-scrollbar style="height: 100%">
            <div id="comment" class="col-md-9 rightBox">
              <div style="margin: 10px 0">
                <h3 style="margin-top:0">高亮文字</h3>
                <p style="font-style: italic; color: #ff915b">
                  {{ currentHLText }}
                </p>
                <!-- 高亮名字、高亮时间 -->
                <p>
                  {{ currentHLTextName }}
                </p>
                <p>
                  {{ currentHLTextTime }}
                </p>
                <el-button
                  id="delHLbtn"
                  type="primary"
                  @click="delHL();"
                  :disabled="delHLbtnDis"
                  icon="el-icon-delete"
                  size="mini"
                  >删除</el-button
                >
                <el-button @click="enterToboard">开启协作白板
                </el-button>
              </div>

              <CommentContent
                v-bind:articleId="this.articleId"
                v-bind:hlId="this.currentHLId"
                v-bind:comment="this.hlComments"
                v-bind:commentText="this.currentHLText"
                v-bind:replyment="this.replyment"
                v-bind:disDiv="this.delHLbtnDis"
                @child="father"
                @childhuifu="fatherhuifu" 
              />
              </div>
              <StuChat />
              </el-scrollbar>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Highlighter from "web-highlighter";
import LocalStore from "../local.store.js";
import axios from "axios";
import CommentContent from "./CommentContent";
import StuChat from "./StuChat"
// let highlighter = null;
let highlighter= null
const store = new LocalStore();
const log = console.log.bind(console, "[highlighter]");
sessionStorage.setItem("init", 0);

export default {

  data() {
    return {
      leftPart: [
        { name: "仅自己可见" },
        { name: "仅小组可见" },
      ],
      boardWindow: null,
      changeLeftBackground: 3,
      message: null,
      articleId: "",
      articleHtml: "", //所需要高亮评论的文本
      currentHLText: "",
      currentHLTextName: "",
      currentHLTextTime: "",
      currentHLId: "",
      authority: 1, //小组是1,个人是0,公共是2
      delHLbtnDis: true,
      hlComments: [], //所选择高亮的所有评论
      replyment: [], //评论的回复
    };
  },
  mounted() {
    
    this.currentTime();
// 阅读任务 axios localStorage.getItem("userId");
    highlighter = new Highlighter({
      $root: document.getElementById("articleDiv"),
      verbose:true,
      wrapTag:"my-highlight",
      style:{
        className:'highlight-wrap'
      }
    });
    //显示颜色

  
    //根据阅读课程ID和阅读任务ID,显示响应的富文本
    var value = sessionStorage.getItem("readingtaskId");
    this.articleId = value;
    if (this.articleId == 0) {
      this.$message.warning("请先选择一项阅读任务");
    } else {
      axios
        .post(axios.defaults.baseURL + "selectContent", {
          id: value,
        })
        .then((response) => {
          let textareaHtml = response.data.data.content;
          if (textareaHtml) {
            this.articleHtml = textareaHtml;
          }
        });
    }

    highlighter.hooks.Serialize.Restore.tap((source) =>
      log("Serialize.Restore hook -", source)
    );

    highlighter.hooks.Serialize.RecordInfo.tap(() => {
      const extraInfo = Math.random().toFixed(4);
      log("Serialize.RecordInfo hook -", extraInfo);
      return extraInfo;
    });

    /**
     * highlighter event listener
     */
    highlighter
      .on(Highlighter.event.CLICK, ({ id }) => {
        var hlDoms = highlighter.getDoms(id);
        const position = this.getPosition(hlDoms);
        this.delHLbtnDis = false;
        this.currentHLId = id;
        sessionStorage.setItem("highlightId", this.currentHLId);
        this.currentHLText = "";
        //设置高亮文字
        for (var i in hlDoms) {
          this.currentHLText += hlDoms[i].innerText;
        }
        this.saveLog(
          "查看当前选中的高亮<" + this.currentHLText.toString() + ">",
          this.nowDate
        );
        var userId = localStorage.getItem("userId");
        var username = localStorage.getItem("username");
        var groupId = localStorage.getItem("groupId");
        var color = localStorage.getItem("color");
       axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username: username,
          groupId: groupId,
          stuId: userId,
          log: "查看当前选中的高亮<" + this.currentHLText.toString() + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});

        //设置添加高亮的人、高亮时间
        axios
          .get(axios.defaults.baseURL + "articleTask/showHighlight", {
            params: {
              highlightId: this.currentHLId,
            },
          })
          .then((res) => {
            axios
              .get(axios.defaults.baseURL + "findUserById", {
                params: {
                  id: res.data.data.userId,
                },
              })
              .then((res) => {
                this.currentHLTextName = res.data.data.username;
              });
            this.currentHLTextTime = res.data.data.createTime;
            this.currentAuthority = res.data.data.currentAuthority;
          });
        this.hlComments = this.getCommentsByHLid(id, this.authority);
        //log("click -", id);
      })
      .on(Highlighter.event.CREATE, ({ sources }) => {
        if (sessionStorage.getItem("init") != 1) {
          //log("create -", this.authority + sources);
          sources = sources.map((hs) => ({ hs }));
          store.save(this.authority, sources);
        }
      })
      .on(Highlighter.event.REMOVE, ({ ids }) => {
        if (sessionStorage.getItem("init") != 1) {
          //log("remove -", ids);
        }
      })
      .on(Highlighter.event.HOVER, ({ id }) => {
        //if (sessionStorage.getItem("init") != 1) {
          highlighter.addClass("highlight-wrap-hover", id);
        //}
        
        //log("hover - ", id);
      })
      .on(highlighter.event.HOVER_OUT, ({ id }) => {
        //if (sessionStorage.getItem("init") != 1){
          highlighter.removeClass("highlight-wrap-hover", id);
       // }
      });

    let hoveredTipId;
    document.addEventListener("mouseover", (e) => {
      const $ele = e.target;
      // toggle highlight hover state
      if (
        $ele.classList.contains("my-remove-tip") &&
        hoveredTipId !== $ele.dataset.id
      ) {
        hoveredTipId = $ele.dataset.id;
        highlighter.removeClass("highlight-wrap-hover");
        highlighter.addClass("highlight-wrap-hover", hoveredTipId);
      } else if (
        !$ele.classList.contains("my-remove-tip") &&
        !$ele.classList.contains("highlight-wrap")
      ) {
        highlighter.removeClass("highlight-wrap-hover", hoveredTipId);
        hoveredTipId = null;
      }
    });

    //自定义一个PC端浏览器高亮按钮
    var highlight_btn = function (e) {
      e = e || window.event;
      var mx = e.clientX;
      var my = e.clientY;
      var articleDiv = document.getElementById("articleDiv");
      var scro = document.getElementById("scro");
      var selection1 = window.getSelection();
      console.log(selection1.toString())
      if (
        mx > scro.offsetLeft &&
        mx < scro.offsetLeft + scro.offsetWidth &&
        my > scro.offsetTop &&
        my < scro.offsetTop + scro.offsetHeight &&
        selection1.toString().length > 0 &&
        selection1.toString() != "\n"
      ) {
        var rm = document.getElementById("js-highlight");
        var rmWidth = parseInt(rm.style.width);
        var pageWidth = document.documentElement.clientWidth;
        if (mx + rmWidth < pageWidth) {
          rm.style.left = e.pageX + "px";
          rm.style.top = e.pageY + "px";
          rm.style.position = "absolute";
        } else {
          rm.style.left = e.pageX + "px";
          rm.style.top = e.pageY + "px";
        }
        rm.style.display = "block";
      } else rm.style.display = "none";
    };

    document.documentElement.onmouseup = highlight_btn;
    
    //自定义一个移动端浏览器高亮按钮
    var touchHighlightBtn = function (event) {
        event = event || window.event;
        var touchX = event.changedTouches[0].clientX;
        var touchY = event.changedTouches[0].clientY;
        var articleDiv = document.getElementById("articleDiv");
        var scro = document.getElementById("scro");
        var selection1 = window.getSelection();
        console.log(selection1.toString())
        if (
          touchX > scro.offsetLeft &&
          touchX < scro.offsetLeft + scro.offsetWidth &&
          touchY > scro.offsetTop &&
          touchY < scro.offsetTop + scro.offsetHeight &&
          selection1.toString().length > 0 &&
          selection1.toString() != "\n"
        ) {
          var rm = document.getElementById("js-highlight");
          var rmWidth = parseInt(rm.style.width);
          var pageWidth = document.documentElement.clientWidth;
          if (touchX + rmWidth < pageWidth) {
            rm.style.left = touchX + "px";
            rm.style.top = touchY + "px";
            rm.style.position = "absolute";
          } else {
            rm.style.left = touchX + "px";
            rm.style.top = touchY + "px";
          }
          rm.style.display = "block";
        } else rm.style.display = "none";
    };

    document.documentElement.addEventListener('touchstart', touchHighlightBtn, { passive: false });
    
    this.leftPart.forEach(() => {
      this.showArticleHL(1);
      this.leftChange(1);
    });
  
  },

  methods: {
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位,则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位,则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位,则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },
    //分页
    currentChange(val) {
      var value = 32;
      if (val == 1) {
        value = 32;
        this.articleId = 32;
      } else if (val == 2) {
        value = 33;
        this.articleId = 33;
      } else if (val == 3) {
        value = 34;
        this.articleId = 34;
      } else if (val == 4) {
        value = 35;
        this.articleId = 35;
      } else {
        this.articleId = sessionStorage.getItem("readingtaskId");
      }
      axios
        .post(axios.defaults.baseURL + "selectContent", {
          id: value,
        })
        .then((response) => {
          let textareaHtml = response.data.data.content;
          if (textareaHtml) {
            this.articleHtml = textareaHtml;
          }
        });
      sessionStorage.setItem("currentPage", this.articleId);
      this.saveLog("第" + val + "个阅读任务", this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username: username,
          groupId: groupId,
          stuId: userId,
          log: "第" + val + "个阅读任务",
          createTime: this.nowDate,
        })
        .then((res) => {});
    },
    leftChange(index) {
      this.changeLeftBackground = index;
    },
    //子组件向父组件传数据
    father(data) {
      this.hlComments = data;
    },
    //子组件向父组件传数据
    fatherhuifu(data) {
      this.replyment = data;
    },
    // 显示当前文章的高亮信息
    showArticleHL(authority) {
      this.saveLog("上方查看高亮按钮", this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "上方查看高亮按钮",
          createTime: this.nowDate,
        })
        .then((res) => {});
      this.hlComments = null;
      this.replyment = null;
      this.currentHLText = null;
      this.currentHLTextName = null;
      this.currentHLTextTime = null;
      this.currentAuthority = null;
      this.authority = authority;
      var requestURL = "";
      var requestParams = {};
      var articleId = sessionStorage.getItem("readingtaskId");
      var groupId = localStorage.getItem("groupId");
      console.log("RT"+groupId);
      var userId = localStorage.getItem("userId");
      sessionStorage.setItem("authority", this.authority);
      switch (this.authority) {
        case 0: //个人
          requestURL =
            axios.defaults.baseURL + "articleTask/showHighlightInfoListByUser";
          requestParams = { articleId: articleId, userId: userId ,groupId: 0};
          break;
        case 1: //小组
          requestURL =
            axios.defaults.baseURL + "articleTask/showGroupHighlightInfoList";
          requestParams = { articleId: articleId, groupId: groupId };
          break;
        case 2: //公共
          requestURL =
            axios.defaults.baseURL + "articleTask/showPublicHighlightInfoList";
          requestParams = { articleId: articleId };
      }
      axios
        .get(requestURL, {
          params: requestParams,
        })
        .then((response) => {
          sessionStorage.setItem("init", 1);
          const storeInfos = response.data.data;
          highlighter.removeAll();
          //画出所有高亮内容
          for (var i in storeInfos) {
            highlighter.fromStore(
              storeInfos[i].hs.startMeta,
              storeInfos[i].hs.endMeta,
              storeInfos[i].hs.text,
              storeInfos[i].hs.id
            );
            // 判断高亮是否是本人
            axios.get(axios.defaults.baseURL+"articleTask/showAuthority",{
              params:{
                highlightId:storeInfos[i].hs.id
              }
            }).then((res)=>{
              if(res.data.data.current_authority==1){
                this.highlighter.style.className="highlight-wrap-other"
                console.log("group")
              }
              else if(res.data.data.current_authority==0){
                this.highlighter.style.className="highlight-wrap"
                console.log("personal")
              }
            })
          };
          sessionStorage.setItem("init", 0);
        })
        .catch(function (error) {
          alert(error);
          return [];
        });
    },
    
    async fetchColorAndStoreInLocalStorage() {
      try {
        // 从localStorage获取stuId
        const stuId = localStorage.getItem('chatId'); // 确保这里的键名与存储时的一致
        // 使用stuId调用API获取color值
        const response = await axios.get(axios.defaults.baseURL+'color', {
          params: {
            stuId: this.chatId,
          },
        });

        // 假设后端返回的color在response.data中
        const color = response.data.color;
        if (color) {
          this.color = color; // 保存到组件的data中
          localStorage.setItem('color', color); // 存储到localStorage中
        }
      } catch (error) {
        console.error('Error fetching color:', error);
        // 错误处理，例如显示错误信息
      }
    },
    
    async enterToboard() {
    try {
      const username = localStorage.getItem("username");
      const color = localStorage.getItem('color');
      const groupId = localStorage.getItem('groupId');
      const readingtaskId = sessionStorage.getItem('readingtaskId');
      // 存储数据到 sessionStorage
      sessionStorage.setItem('color', color);
      sessionStorage.setItem('username', username);
      const response = await axios.get(axios.defaults.baseURL+'baiban/getRoomId', {
          params: {
            groupId: groupId,
            readingtaskId: readingtaskId
          }
        });
        const roomId = response.data; // 获取房间ID
        let newWindow;
        if(roomId === -1 || roomId === -2){
          alert("你还没有选择阅读任务");
        }else if (roomId === 0){
          //生成随机字符串
          function generateRandomString(length) {
          let result = '';
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          const charactersLength = characters.length;
          for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
           }return result;}
           //拼接
          const randomString1 = generateRandomString(20);
          const randomString2 = generateRandomString(22);
          const roomString = randomString1 + "," + randomString2;
          //发送请求
          axios.post(axios.defaults.baseURL+'baiban/save',{
            roomId: roomString,
            groupId:localStorage.getItem('groupId'),
            readingtaskId:sessionStorage.getItem('readingtaskId'),
          })
          //打开新房间 


          newWindow = window.open('https://huatu.iris-sci.cn' + '#room=' + roomString /*+ urlParams*/, '_blank');

          
          if (newWindow) {
      console.log('New window opened:', newWindow);

      // 尝试立即发送消息，然后再尝试监听onload事件
      setTimeout(() => {
        if (!newWindow.closed) {
          newWindow.postMessage({
            color: color,
            username: username
          }, 'https://huatu.iris-sci.cn');
          console.log('Message sent to new window:', { color, username });
        } else {
          console.log('New window is already closed.');
        }
      }, 100); // 延迟100毫秒

      // 监听窗口关闭事件
      newWindow.addEventListener('beforeunload', () => {
        console.log('New window is being closed.');
      });
    } else {
      console.error('Failed to open new window.');
    }
 }else{
          //打开已有房间

          console.log('打开已有房间');
          const newWindow = window.open('https://huatu.iris-sci.cn' + '#room=' + roomId /*+ urlParams*/, '_blank');

          if (newWindow) {
      console.log('New window opened:', newWindow);

      // 尝试立即发送消息，然后再尝试监听onload事件
      setTimeout(() => {
        if (!newWindow.closed) {
          newWindow.postMessage({
            color: color,
            username: username
          }, 'https://huatu.iris-sci.cn');
          console.log('Message sent to new window:', { color, username });
        } else {
          console.log('New window is already closed.');
        }
      }, 100); // 延迟100毫秒

      // 监听窗口关闭事件
      newWindow.addEventListener('beforeunload', () => {
        console.log('New window is being closed.');
      });
    } else {
      console.error('Failed to open new window.');
    }







    }   
      } 
    catch (error) {
        console.error('Error fetching room ID: ', error);
      }
    },
    
    getPosition($node) {
      let offset = {
        top: 0,
        left: 0,
      };
      while ($node) {
        offset.top += $node.offsetTop;
        offset.left += $node.offsetLeft;
        $node = $node.offsetParent;
      }
      return offset;
    },

    /**
     * avoid re-highlighting the existing selection
     */
    getIds(highlighter, selected) {
      if (!selected || !selected.$node || !selected.$node.parentNode) {
        return [];
      }
      return [
        highlighter.getIdByDom(selected.$node.parentNode),
        highlighter.getExtraIdByDom(selected.$node.parentNode),
      ].filter((i) => i);
    },

    getIntersection(arrA, arrB) {
      const record = {};
      const intersection = [];
      arrA.forEach((i) => (record[i] = true));
      arrB.forEach(
        (i) => record[i] && intersection.push(i) && (record[i] = false)
      );
      return intersection;
    },

     highlightText() {
      const selection = window.getSelection();
      if (!selection.isCollapsed) {
        highlighter.fromRange(selection.getRangeAt(0));
        this.saveLog("高亮按钮", this.nowDate);
        var userId = localStorage.getItem("userId");
        var username = localStorage.getItem("username");
        var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "高亮按钮",
          createTime: this.nowDate,
        })
        .then((res) => {});
      }
      //不需要时隐藏右键菜单
        document.getElementById("js-highlight").style.display = "none";
      //window.getSelection().removeAllRanges();
    },

    delHL() {
      this.saveLog("删除高亮<" + this.currentHLText + ">", this.nowDate);
      var userId = localStorage.getItem("userId");
      var username = localStorage.getItem("username");
      var groupId = localStorage.getItem("groupId");
      axios
        .post(axios.defaults.baseURL + "addAllLogs", {
          username:username,
          groupId:groupId,
          stuId: userId,
          log: "删除高亮<" + this.currentHLText + ">",
          createTime: this.nowDate,
        })
        .then((res) => {});
      const id = this.currentHLId; // stored highlight id
      log("*click remove-tip*", id);
      var userId = localStorage.getItem("userId");
      axios
        .get(axios.defaults.baseURL + "articleTask/deleteHighlight", {
          params: {
            userId: userId,
            highlightId: id,
          },
        })
        .then((ret) => {
          let code = ret.data.status;
          if (code == 1) {
            this.$message.success("删除成功");
            highlighter.remove(id);
            //highlighter.removeClass("highlight-wrap-hover", id);
            this.delHLbtnDis = true;
            this.currentHLId = "";
            this.currentHLText = "";
            this.currentHLTextName = "";
            this.currentHLTextTime = "";
            this.currentAuthority = "";
            this.hlComments = [];
            this.replyment = [];
          } else {
            this.$message.error("删除失败");
          }
        });
    },

    //通过高亮ID从数据库获取所有的评论
    getCommentsByHLid(hlId, authority) {
      hlId = this.currentHLId;
      var userId = localStorage.getItem("userId");
      var groupId = localStorage.getItem("groupId");
      if (authority == 0) {
        axios
          .post(axios.defaults.baseURL + "listPingLunByHId", {
            highLightId: hlId,
            authority: authority,
            userId: userId,
          })
          .then((response) => {
            this.hlComments = response.data.data;
            //console.log("个人评论");
          })
          .catch((err) => {
            console.log(err);
          });
        return [];
      } else if (authority == 1) {
        axios
          .post(axios.defaults.baseURL + "listPingLunByHId", {
            highLightId: hlId,
            authority: authority,
            groupId: groupId,
            // userId: 0,
          })
          .then((response) => {
            this.hlComments = response.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
        return [];
      } else if (authority == 2) {
        axios
          .post(axios.defaults.baseURL + "listPingLunByHId", {
            highLightId: hlId,
            authority: authority,
          })
          .then((response) => {
            this.hlComments = response.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
        return [];
      } else {
        this.$message.warning("权限错误");
      }
    },
    
  },

  beforeDestroy() {
    document.documentElement.onclick = function (e) {};
    document.documentElement.oncontextmenu = function (e) {};
    sessionStorage.removeItem("init");
  },
  components: {
    CommentContent,
    StuChat,
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-card__body{
  padding-top: 10px;
}

/deep/ .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}

.liBackground {
  background: #409eff;
  color: #fff;
}
.rightBox {
  background: #fff;
  padding-bottom: 10px;

}
.btnHighlight {
  width: 80px;
  z-index: 1000;
}
.selfContainer img {
  display: block !important;
  width: 50%;
  margin: 0 auto !important;
  //background: #409eff;
}
.htmlClass{
  font-size: 20px;
}
</style>
